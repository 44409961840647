module.exports = {
    message: {
      changeLang:'EN/CN',
      login: 'Login',
      Username: 'Username',
      Password: 'Password',
      Captcha: 'Captcha',
      Language: 'EN',
      zh: 'Chinese',
      en: 'English',
      more:'More',
      more2:'More',
      msg1:'Quote',
      msg2:'Book',
      msg3:'Track',
      msg4:'Request an instant quote for:',
      msg5:'Seafreight LCL',
      msg6:'Airfreight',
      msg7:'Overland',
      msg8:'from country',
      msg9:'to country',
      msg10:'Get a quote',
      msg11:'Get a book',
      msg12:'Search',
      msg13:'Submit your application online',
      msg14:'Get your instant quote',
      msg15:'Innovative logistics solutions',
      msg16:'With a broad portfolio of products and solutions, you can fully meet your logistics requirements, from simple courier and transportation to revolutionary solutions, across the supply chain.',
      msg17:'forget password',
      msg18:'forget username',
      msg19:'Client',
      msg192:'Employee',
      msg20:'International logistics solutions you can trust',
      msg21:'The new era uses advanced electronic information technology to provide customers with a comprehensive and comprehensive supply chain solution, and strengthens the entire logistics chain through optimized integration services such as international freight forwarding and domestic logistics.',
      msg22:'Technology platform docking',
      msg23:'We work with many industry-leading organizations to help streamline your logistics and transportation processes, allowing you to invest more time in other key areas of your business.',
      msg24:'News Center',
      msg25:'Retail logistics',
      msg26:'Case study',
      msg27:'Big data in logistics management',
      msg28:'Resources',
      msg29:'Air Waybill Terms and Conditions',
      msg30:'Forms and Downloads',
      msg31:'Ocean House Bill Terms & Conditions',
      msg32:'Search results',
      msg33:'We found',
      msg34:'Results about',
      msg35:'Search',
      msg36:'International Chamber of Shipping',
      msg37:'Accurate location, accurate time, and affordable price. Rich equipment and LCL solutions.',
      msg38:'Our logistics consultants will provide you with professional customized solutions.',
      msg39:'Inquire Now',
      msg40:'Loading...',
      msg41:'Loading More',
      msg42:'More news',
      msg43:'Please enter order number',
      msg44:'Please enter verification code',
      msg45:'forget password',
    }
   }