<template>
  <div id="app">
    <v-header></v-header>
    <router-view />
    <v-footer></v-footer>
    <img class="fix-btn" @click="goTop" src="./assets/gotop.png" >
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    "v-header": Header,
    "v-footer": Footer
  },
  watch: {
    $route: function(to, from) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  },
  methods:{
    goTop(){
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }
};
</script>
<style lang="scss">
@font-face {
    font-family: Source Han Sans CN;//自定义字体名称
    src: url(./assets/fonts/SourceHanSansCN-Normal.otf)
}
html,body{
  background: #F3F4F5 !important;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
 
font-family: PingFangSC-Regular, PingFang SC;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none;
  cursor: pointer;
}
a,
a:hover,
a:active,
a:visited,
a:link,
a:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
  background: none;
  text-decoration: none;
}

.w1200 {
  max-width: 1200px;
  margin: 0 auto;
}
@media (min-width: 1921px) {
  .w1200 {
    // max-width: 1920px;
    margin: 0 auto;
  }
  .section {
    max-width: auto;
  }
}
// @media (max-width: 1367px) {
//   .w1200 {
//     max-width: 960px;
//   }
// }
@media (min-width: 768px) and (max-width: 1367px) {
  body {
    zoom: 1;
  }
  .w1200 {
    width: 96%!important;
  }
}
@media (max-width: 767px) {
  .w1200 {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.btn-more {
  width: 156px;
  height: 49px;
  line-height: 49px;
  display: inline-block;
  background: rgba(67, 176, 212, 1);
  box-shadow: 0px 3px 8px 0px rgba(34, 116, 143, 0.32);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  color: #fff;
  text-shadow: 0px 16px 32px rgba(0, 43, 85, 0.04);
}
.btn-more img {
  width: 16px;
  height: 16px;
  margin-left: 6px;
  vertical-align: middle;
}
.cur-pointer {
  cursor: pointer;
}

.section {
  border-radius: 0px 0px 8px 8px;
}
.section img {
  // border-radius: 8px 8px 0px 0px;
}
.fix-btn{
  display: none;
  position: fixed;
  bottom: 30px;
  right: 20px;
  width: 40px;
  z-index: 99;
  height: 40px;
  border-radius: 50%;
  background: #fff;
}
@media (max-width: 768px) {
  .fix-btn {
    display: inline-block;
  }
}
.news-content {
      img {
        max-width: 100% !important;
        height: auto!important;
      }
    }
    .ddds{
  left: 16px;
  right: 16px;
  width: auto;
}
</style>
