module.exports = {
    message: {
      changeLang:'EN/CN',
      login: '登录',
      Username: '请输入用户名',
      Password: '请输入密码',
      Captcha: '验证码',
      Language: 'CN',
      zh: '中文',
      en: '英文',
      more:'阅读更多',
      more2:'了解更多',
      msg1:'获得报价',
      msg2:'预定物流',
      msg3:'货运跟踪',
      msg4:'请选择您需要报价的物流方式',
      msg5:'国际海运',
      msg6:'国际空运',
      msg7:'陆运',
      msg8:'请选择出发国家',
      msg9:'请选择到达国家',
      msg10:'获得报价',
      msg11:'预定物流',
      msg12:'货运跟踪',
      msg13:'在线递交您的申请',
      msg14:'获取您的定制报价方案',
      msg15:'物流创新解决方案',
      msg16:'拥有广泛的产品和解决方案组合，能充分满足您的物流要求，从简单快递和运输到革命性的解决方案，跨越供应链，应有尽有。',
      msg17:'忘记密码',
      msg18:'忘记用户名',
      msg19:'客户',
      msg192:'员工',
      msg20:'值得您信任的国际物流方案',
      msg21:'新时代运用先进的电子信息技术，为客户提供全方位综合性的供应链解决方案，并通过国际货运代理及国内物流等优化整合服务来加强整个物流链。',
      msg22:'技术平台对接',
      msg23:'我们与许多行业领先的组织合作，帮助简化您的物流和运输流程，让您可以将更多时间投入到业务的其他关键领域。',
      msg24:'新闻中心',
      msg25:'零售行业物流',
      msg26:'案例研究',
      msg27:'物流管理大数据化',
      msg28:'资源',
      msg29:'航空分运单条款和条件',
      msg30:'表格与下载',
      msg31:'海运分运单条款和条件',
      msg32:'搜索结果',
      msg33:'我们找到了',
      msg34:'条结果关于',
      msg35:'搜索',
      msg36:'国际海运',
      msg37:'准确的地点、准确的时间、实惠的价格。丰富的设备和拼箱方案。',
      msg38:'我们的物流顾问将为您提供专业的定制化方案。',
      msg39:'立即咨询方案',
      msg40:'加载中',
      msg41:'加载更多',
      msg42:'更多新闻',
      msg43:'请输入主单号',
      msg44:'请输入验证码',
      msg45:'忘记密码'
    }
  }