<template>
  <div class="footer">
    <div class="footer-top w1200">
<div class="footer-img">
<img src="../assets/logo-w.png" class="footer-logo" alt=""><img src="../assets/phone.png" class="footer-phone" alt="">
</div>
          <div  class="footer-list cur-pointer" v-for="(item,index) in navs" :key="index"  @click="goPage(item)">{{item.name}}</div>

    </div>
    <div class="footer-bottom w1200">
      <div class="footer-info">
       Copyright © 2018-2019 www.minstech.cn. All Rights Reserved
        <br /><a href="http://www.beian.miit.gov.cn/" target="_blank">浙ICP备18033054号</a>
         <!-- <a href="http://www.beian.miit.gov.cn" target="_blank">公安机关备案号：11011302000273</a> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      navs: []
    };
  },
  mounted() {
    this.getMenu();
  },
  methods: {
    //底部导航
    getMenu() {
      let self = this;
      this.$axios
        .get(`/api/portal/navMenu/menu?lang=${this.$i18n.locale}&nav=footer`)
        .then(function(res) {
          Object.keys(res.data.data).forEach(function(key) {
            res.data.data[key].showChild = false;
          });
          self.navs = res.data.data;
        });
    },
    //子项切换
    ToggleChild(item) {
      item.showChild = !item.showChild;
    }
    ,
     goPage(item) {
      this.showSearch = false;
      this.showNavs = false;
      if (item.href.indexOf("http") > -1) {
        window.location.href = item.href;
        return;
      }
      let id = item.href.substr(-2);
      console.log(item, id);
      id = id.replace("=", "");
      let name = item.name;
      if (item.name == "首页" || item.name == "Home") {
        this.$router.push("/");
        return;
      }
      // if(isNaN(id)){
      //   return
      // }
      console.log(id, item.name);
      let self = this;

      if (item.lcj_type == "category") {
       let cates = document.getElementById('cates');
       cates.scrollIntoView(true);
       document.body.scrollTop=document.documentElement.scrollTop = cates.offsetTop - 90
        // this.$axios
        // .get(`/api/portal/categories/${id}&lang=${this.$i18n.locale}`)
        // .then(function(res) {
        //   console.log(res);
        // if(res.data.data.type == 'file'){
        // self.$router.push(`/tabledownload?id=${id}&name=${name}`);
        // }else{
        //     self.$router.push(`/news?id=${id}&name=${name}`);
        // }
        // });
        this.$EventBus.$emit('goNav',id)
      } else if(item.lcj_type == "extra_page"){
        this.$router.push("/");
        let solution = document.getElementById('solution')
       solution.scrollIntoView(true)
       
document.body.scrollTop=document.documentElement.scrollTop = solution.offsetTop - 90

      } else {
        this.$router.push("/detail?id=" + id);
      }
    },
  },
  watch: {
    "$i18n.locale"() {
      this.getMenu();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
  background: #212327;
  .footer-phone{
  width: 110px;
  vertical-align: super;
  margin-left: 10px;
}
  .footer-top {
    padding: 64px 16px;
    .footer-title {
      font-size: 18px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 33px;
      margin-bottom: 16px;
    }
    .footer-list {
      
     margin-bottom: 24px;
font-size: 16px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 25px;
    }
    .footer-title-phone{
        display: none;
      }
      .footer-list-phone{
        display: none;
      }
  }
  .footer-bottom {
    width: 100%;
    border-top: 1px solid #FFFFFF;
font-size: 16px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 50px;

    .footer-info {
   
      text-align: left;
      word-break: break-word;
      br {
        display: none;
      }
    }
  }
}
.footer-img{
  margin-bottom: 74px;
}
.footer-logo{
  width: 240px;
}
@media (max-width: 768px) {
  .footer-img{
  margin-bottom: 48px;
}
.footer-logo{
  width: 200px;
}
.footer-phone{
  width: 110px;
  vertical-align: super;
  margin-left: 10px;
}
  .footer {
    padding: 32px 16px 0px 16px;
    .footer-top{
      padding: 0px;
      .footer-list {
      
     margin-bottom: 24px;

font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 20px;
    }
      .footer-title-phone{
        display: block;
         font-size: 20px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 33px;
      padding: 16px;
      border-top: 2px solid rgba(255, 255, 255, 0.14);
      position: relative;
       text-align: left;
      img{
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
      }
      }
      .footer-list-phone{
        padding-left: 16px;
        display: block;
        font-size: 14px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
      line-height: 34px;
      }
    }
    .footer-bottom {
       text-align: left;
       padding: 12px 0px;
font-size: 13px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 18px;
      .footer-imgs {
       
        img {
          margin-left: 0px;
        }
      }
      .footer-info {
        text-align: left;
        br {
          display: block;
        }
      }
    }
  }
}

</style>
