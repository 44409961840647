<template>
  <div>
    <div class="main">
      <div class="main-slide">
        <slider
          animation="fade"
          @change="changeSwiper"
          width="100%"
          :height="swiperHeight"
          :interval="8000"
          :indicators="false"
        >
          <slider-item v-for="(item,index) in swiper" :key="index">
            <div class="main-bannar-wrap">
              <!-- <img class="main-bannar" :src="imgUrl + item.image" alt srcset /> -->
              <div class="main-bannar" :style="'background-image:url('+ imgUrl + item.image + ')'"></div>
              <img class="main-bannar-phone" :src="imgUrl + item.image" alt />
            </div>
          </slider-item>
        </slider>
      </div>
<div class="main-content w1200">
        <div
          class="main-section"
          @click="goNewsDetail(swiper[currentSwiper] && swiper[currentSwiper].url)"
        >
          <div class="main-title">{{swiper[currentSwiper] && swiper[currentSwiper].title}}</div>
          <div class="main-des">{{swiper[currentSwiper] && swiper[currentSwiper].description}}</div>
          <div class="main-btn btn-more section-more" style="background:#F8BA05;float:right">
            {{ $t("message.more") }}
            <img src="../assets/right.png" alt srcset />
          </div>
        </div>
</div>
    
    </div>
   
    <div class="form-wrap w1200">
     <div class="form-content">
        <div class="form-tit">点击此处，我们将为您提供定制化解决方案报价</div>
       <div class="form-tip">请选择一种您的商业服务类型：</div>
        <div
        class="form-cate"
        v-for="(item, index) in solutions"
        :key="index"
        @click="changeSolution(item.id)"
      >
      <img v-if="selectSolution == item.id" src="../assets/checked.png" alt="" srcset="">
      <img v-else src="../assets/check.png" alt="" srcset="">
        {{ item.name }}
      </div>
       <div class="section-more " @click="getReport">
                获得报价 <img src="../assets/right.png" alt="" srcset="" />
              </div>
     </div>
     <img :src="imgUrl +  detail.image" alt="">
    </div>
      <div id="solution" class="solution-wrap w1200">
      <div class="solution" v-for="(item,index) in slides" :key="index" @click="goNewsDetail(item.url)">
        <div class="solution-content">
           <div class="solution-time">
         解决方案
        </div>

        <div class="solution-title"> {{ item.title }}</div>
        <div class="solution-des" >{{ item.content }}</div>
        <div class="solution-more">
          了解更多 <img src="../assets/right.png" alt="" srcset="" />
        </div>
        </div>
        <img :src="imgUrl + item.image" alt="">
      </div>
    </div>
   <div id="cates" class="sec-title w1200">时乘软件服务：</div>
    <div class="sec-cates w1200">
      <div style="margin-right:-24px">
      <div
        :class="['sec-cate', selectCate == item.category.id ? 'sec-cate-active' : '']"
        v-for="(item, index) in navs"
        :key="index"
        @click="changeCate(item.category.id)"
      >
        {{ item.name }}
      </div>
      </div>
    </div>
    <div class="sec-cates-phone w1200">
      <Dropdown @on-click="changeCate"
        style="display: block"
        trigger="click"
        transfer
        transfer-class-name="ddds"
      >
        <div class="sec-phone-cate">
          <span  v-for="item in navs" v-if="selectCate == item.category.id">{{item.name}}</span>
     
          <img src="../assets/icon_down.png" alt="" srcset="" />
        </div>
        <DropdownMenu slot="list" style="left: 16px; right: 16px; width: auto">
          <DropdownItem
            v-for="item in navs"
            :key="item.name"
            :name="item.category.id"
            >{{ item.name }}</DropdownItem
          >
        </DropdownMenu>
      </Dropdown>
    </div>
    <div class="sections w1200">
      <Row type="flex" justify="space-between">
        <Col
          :xs="24" :sm="11"  :lg="7"
          v-for="(item, index) in sections"
          :key="index"
        >
          <div class="section-wrap" @click="goNewsDetail(item.id)">
            <div class="section">
              <div class="section-time">
                {{ item.categories && item.categories[0].name }}
              </div>
              <img class="section-img" :src="item.thumbnail" alt />

              <div class="section-title">{{ item.post_title }}</div>
              <div class="section-more">
                了解更多 <img src="../assets/right.png" alt="" srcset="" />
              </div>
            </div>
          </div>
        </Col>
        <Col :xs="24" :sm="11"  :lg="7"> </Col>
        <Col :xs="24" :sm="11"  :lg="7"> </Col>
      </Row>
    </div>
    <div class="contact-wrap w1200" @click="goNewsDetail(contact.url)">
      <div class="contact">
        <div class="contact-time">
          {{ contact.title }}
        </div>

        <div class="contact-title">{{ contact.content }}</div>
        <div class="contact-more" @click="getReport">
          了解更多 <img src="../assets/right-w.png" alt="" srcset="" />
        </div>
      </div>
      <img class="contact-img" :src="imgUrl + contact.image" alt />
    </div>
  </div>
</template>

<script>
import { Slider, SliderItem } from "vue-easy-slider";
export default {
  name: "home",
  components: {
    Slider,
    SliderItem,
  },
  data() {
    return {
      chooseTab: 0,
      chooseWay: "",
      slides: [],
      swiper: [{ title: "" }],
      swiper2: [],
      navs: [],
      middleNavs: [],
      sections: [],
      currentSwiper: 0,
      swiperHeight: "600px",
      captcha: "",
      code: "",
      MawbNum: "",
      captchaId: "",
       isLoading: false,
      isTotal: false,
      sections: [],
            contact: {},
      selectCate: 0,
      solutions:{},
      detail:{},
      page:1,
      selectSolution:0,
    };
  },
  mounted() {
    this.getSolutions()
    this.getSwiper();
    this.getSwiper2();
    this.getMenu();
    this.getSlide();
     this.getCates();
     
    this.getHeight();
    window.onresize = () => {
      this.getHeight();
    };
        this.$EventBus.$on("goNav", (id) => {
          this.navs.map(v=>{
            if(v.category.id == id){
              this.changeCate(id)
            }
          })
    });
  },
  methods: {
    getReport(){
      this.solutions.map(v =>{
        if(v.id == this.selectSolution){
          this.$router.push("/reportdetail?id=" + v.name);
        }
      })

    },
    getHeight() {
      let width =
        document.documentElement.offsetWidth || document.body.offsetWidth;
   
      if (width >= 751) {
        this.swiperHeight =
          ((width * 425) / 1380 > 600 ? (width * 425) / 1380 : 600) + "px";
      } else {
        this.swiperHeight = "400px";
      }
    },
    changeSwiper(value) {
      this.currentSwiper = value;
    },
    changeLang() {
      if (this.$i18n.locale == "zh") {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "zh";
      }
    },
    changeTab(index) {
      this.chooseTab = index;
    },
    //幻灯片
    getSwiper() {
      let self = this;
      this.$axios
        .get(`/api/portal/slide/other?nav=10&lang=${this.$i18n.locale}`)
        .then(function (res) {
          console.log(res);
          self.swiper = res.data.data;
        });
    },
    //解决方案
    getSlide() {
      let self = this;
      this.$axios
        .get(`/api/portal/slide/other?nav=20&lang=${this.$i18n.locale}`)
        .then(function (res) {
          console.log(res);
          self.slides = res.data.data;
        });
    },
  changeCate(id) {
      this.selectCate = id;
      this.getSections(id);
    },
    changeSolution(id) {
      this.selectSolution = id;
    },
    //分类
    getCates() {
      let self = this;

      this.$axios

        .get(`/api/portal/navMenu/menu?lang=${this.$i18n.locale}&nav=middle`)
        .then(function (res) {
          self.navs = res.data.data;
          self.selectCate = parseInt(res.data.data[0].category.id);
          self.getSections(self.selectCate);
        });
    },
    //文章
    getSections(id) {
      let self = this;
      this.$axios
        .get(
          `/api/portal/lists/getCategoryPostLists?category_id=${id}&page=${this.page}&listRows=60&lang=${this.$i18n.locale}&relation=categories`
        )
        .then(function (res) {
          console.log(res);
          self.sections = res.data.data.list;
        });
    },
 //联系我们
    getSwiper2() {
      let self = this;
      this.$axios
        .get(`/api/portal/slide/other?nav=40&lang=${this.$i18n.locale}&id=25`)
        .then(function (res) {
          self.contact = res.data.data;
        });
    },
    //导航
    getMenu() {
      let self = this;
      this.$axios

        .get(
          `/api/portal/navMenu/menu?lang=${this.$i18n.locale}&nav=middle`
        )
        .then(function (res) {
          self.navs = res.data.data;
        });
    },
   //表单选项
    getSolutions() {
      let self = this;
      this.$axios
        .get(
          `/api/portal/navMenu/menu?lang=${this.$i18n.locale}&nav=type`
        )
        .then(function (res) {
          console.log(res.data.data,33333)
         self.solutions = res.data.data
          self.selectSolution = parseInt(res.data.data[0].id)
           self.getNewsDetail()
            // self.$set(this,solutions,res.data.data)
        });
       
    },
        //文章详情
    getNewsDetail(url) {
      let self = this;
      this.isLoading = true;
      this.$axios.get(`/api/portal/slide/other?nav=40&lang=${this.$i18n.locale}&id=26`).then(function (res) {
        console.log(res);
        self.detail = res.data.data;
      });
    },
    serviceDetail() {
      this.$router.push("/servicedetail");
    },
    news() {
      this.$router.push("/news");
    },
    casestudy() {
      this.$router.push("/casestudy");
    },
    about() {
      this.$router.push("/about");
    },
    tabledownload() {
      this.$router.push("/tabledownload");
    },
    //文章详情
    goNewsDetail(id) {
      if(id){
        this.$router.push("/newsdetail?id=" + id);
      }
    },
    goPage(item) {
      this.showSearch = false;
      this.showNavs = false;
      if (item.href.indexOf("http") > -1) {
        window.location.href = item.href;
        return;
      }
      let id = item.href.substr(-2);
      console.log(item, id);
      id = id.replace("=", "");
      let name = item.name;
      if (item.name == "首页" || item.name == "Home") {
        this.$router.push("/");
        return;
      }
      // if(isNaN(id)){
      //   return
      // }
      console.log(id, item.name);
      let self = this;

      if (item.lcj_type == "category") {
        // this.$axios
        //   .get(`/api/portal/categories/${id}&lang=${this.$i18n.locale}`)
        //   .then(function(res) {
        //     console.log(res);
        //     if (res.data.data.type == "file") {
        self.$router.push(`/tabledownload?id=${id}&name=${name}`);
        //   } else {
        //     self.$router.push(`/news?id=${id}&name=${name}`);
        //   }
        // });
      } else {
        this.$router.push("/detail?id=" + id);
      }
    },
  },

};
</script>
<style lang="scss" scoped>
@media (min-width: 1921px) {
  .w1200 {
    margin: 0 auto;
  }
  .main-content {
    .form {
      // transform: scale(1.2);
      transform-origin: left top;
    }
    .main-section {
      // transform: scale(1.2);
      transform-origin: right top;
    }
  }
}
.main {
  position: relative;
  width: 100%;
  overflow: hidden;
  .main-bannar-wrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  .main-slide {
  }
  .main-bannar {
    // position: absolute;
    // left: 0;
    // right: 0;
    // top: 0;
    // bottom: 0;
    // left: 50%;
    // transform: translateX(-50%);
    width: 100%;
    // object-fit: cover;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    // display: inline-block;
  }
  .main-bannar-phone {
    display: none;
  }
}
.main > img {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
.main-content {
  .main-section {
    width: calc(100% - 440px);
    text-align: right;
    float: right;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
  }
  width: 100%;
  z-index: 99;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  color: rgba(255, 255, 255, 1);
  .main-title {
    padding: 0px 4px;
    float: right;
    font-size: 42px;
    background: rgba(0, 0, 0, .4);
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
    line-height: 59px;
    margin-bottom: 18px;
  }
  .main-des {
    clear: both;
    max-width: 432px;
    min-height: 32px;
    margin-left: auto;
    font-size: 14px;
    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    line-height: 20px;
    margin-bottom: 40px;
  }
  .main-btn {
    width: 156px;
    height: 49px;
    line-height: 49px;
  }
}
.form {
  float: left;
  .tabs {
    overflow: hidden;
    .tab {
      cursor: pointer;
      width: 114px;
      height: 40px;
      background: rgba(255, 255, 255, 1)
        linear-gradient(
          180deg,
          rgba(239, 251, 255, 1) 0%,
          rgba(222, 247, 255, 1) 100%
        );
      border-radius: 8px 8px 0px 0px;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      color: rgba(67, 176, 212, 1);
      line-height: 40px;
      float: left;
    }
    .tab.tab-active {
      background: rgba(255, 255, 255, 0.8);
      font-weight: bold;
    }
  }
  .tab-content {
    width: 440px;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.24);
    box-sizing: border-box;
    padding: 24px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 35, 60, 0.4);
    border-radius: 0px 8px 8px 8px;
    .tab-title {
      margin-bottom: 24px;
    }
    input {
      height: 32px;
      line-height: 32px;
      border: none;
      border-bottom: 1px dotted rgba(0, 0, 0, 0.24);
      outline: none;
      width: 100%;
      background: transparent;
      margin-bottom: 41px;
    }
    .tab-btn {
      cursor: pointer;
      height: 48px;
      box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.24);
      border: 1px solid rgba(0, 35, 60, 0.4);
      line-height: 48px;
      border: 1px solid rgba(0, 35, 60, 0.4);
      box-sizing: border-box;
      text-align: center;
      margin-top: 10px;
    }
    .ivu-radio-wrapper {
      margin-right: 16px;
      font-size: 18px;
      color: rgba(0, 35, 60, 1);
    }
    .ivu-radio-wrapper-checked {
      color: rgba(67, 176, 212, 1);
    }
  }
}
.sec-cates {
  overflow: hidden;
  display: block;
}
.sec-cates-phone {
  display: none;
  margin-bottom: 16px;
}
.sec-cate {
  float: left;
  margin-right: 24px;
  margin-bottom: 24px;
  line-height: 56px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #080404;
  width: 180px;
  text-align: center;
  height: 56px;
  background: #fff;
  cursor: pointer;
}
.sec-cate:last-child {
  margin-right: 0px;
}
.sec-cate-active {
  line-height: 56px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #080404;
  width: 180px;
  text-align: center;
  height: 56px;
  background: #f8ba05;
  box-shadow: 0px 5px 8px -2px rgba(227, 169, 0, 0.47);
}
.sec-phone-cate {
  padding: 0px 24px;
  width: 100%;
  box-sizing: border-box;
  line-height: 56px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
}
.sec-phone-cate img {
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
}
.sec-title {
  padding: 24px 0px;
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #080404;
  line-height: 33px;
}
.section-wrap {
  overflow: hidden;
  padding: 24px;
  background: #fff;
  margin-bottom: 36px;
}
.section-wrap:hover {
  box-shadow: 0px 7px 24px -2px rgba(8, 4, 4, 0.12);
}
.sections{
  margin-bottom: -36px;
}
.section {
  box-sizing: border-box;
  // max-width: 376px;
  flex: 1 1 300px;

  background: #fff;
  .section-time {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #080404;
    line-height: 22px;
    margin-bottom: 16px;
  }

  .section-title {
    margin: 16px 0px;
    height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #080404;
    line-height: 30px;
  }
  .section-img {
    height: 210px;
    width: 100%;
    object-fit: cover;
  }
  .section-des {
    font-size: 16px;
    height: 48px;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 16px;
    font-weight: 400;
    color: rgba(0, 35, 60, 0.48);
    line-height: 24px;
    margin-bottom: 4px;
    text-shadow: 0px 16px 32px rgba(0, 43, 85, 0.04);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.section-wrap:hover .section-more {
  background: #f8ba05;
}
.section-more {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 156px;
  padding: 12px;
  height: 48px;
  background: #fff;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 21px;
  cursor: pointer;
}
.section-more img {
  width: 22px;
  height: 22px;
}
.ivu-select-dropdown {
  left: 16px;
  right: 16px;
  width: auto;
}
.contact-wrap {
  margin: 72px auto;
  display: flex;
  height: 400px;
  position: relative;
  .contact {
    padding: 64px 24px 87px 48px;
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #f8ba05;
    position: relative;
    z-index: 3;
    .contact-time {
      margin-bottom: 24px;
     
font-size: 18px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: bold;
color: #080404;
line-height: 25px;
    }
    .contact-title {
      margin-bottom: auto;
     
font-size: 32px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: bold;
color: #080404;
line-height: 45px;
    }
    .contact-more {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      width: 156px;
      padding: 12px;
      height: 48px;
      background: #080404;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 21px;
      cursor: pointer;
      img {
        width: 22px;
        height: 22px;
        flex: 0 0 22px;
      }
    }
  }

  img {
    width: 48%;
    object-fit: cover;
    height: 100%;
  }
}
@media (max-width: 768px) {
  .contact-wrap {
    height: 248px;
    .contact {
      padding: 24px;
      background: rgba(248, 186, 5, 0.8);
      .contact-time {
      margin-bottom: 24px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #080404;
      line-height: 20px;
    }
    .contact-title {
      margin-bottom: auto;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: bold;
      color: #080404;
      line-height: 24px;
    }
    }
    > img {
      position: absolute;
      top: 0px;
      left: 16px;
      right: 16px;
      bottom: 0px;
      width: calc(100% - 32px);
    }
  }
  .sec-cates {
    display: none;
  }
  .sec-title {
    padding-left: 16px;
  }
  .section-wrap:hover .section-more {
    background: #fff;
  }
  .sec-cates-phone {
    display: block;
  }
}
.solution{
  display: flex;
  margin: 72px auto;
  
}
.solution:nth-of-type(2n+1){
  flex-direction: row-reverse;
}
.solution > img{
  flex: 0 0 52%;
  width: 52%;
  object-fit: cover;
  height: 463px;
}
.solution-content{
  flex:1;
  box-sizing: border-box;
  padding-left: 56px;
}
.solution:nth-of-type(2n) .solution-content{
  padding-left: 0px;
  padding-right: 56px;
}
.solution-time{
  margin-bottom: 16px;
font-size: 18px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: bold;
color: #080404;
}
.solution-title{
  margin-bottom: 40px;
font-size: 28px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: bold;
color: #080404;
line-height: 40px;
}
.solution-des{
margin-bottom: 56px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #080404;
line-height: 26px;
}
.solution-more {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  width: 156px;
  padding: 12px;
  height: 48px;

background: #F8BA05;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 21px;
  cursor: pointer;
}
.solution-more img {
  width: 22px;
  height: 22px;
}
@media (max-width: 768px) {
  .solution{
    margin: 36px auto;
    > img{
      width: 100%;
      height: 257px;
    }
    .solution-content{
      padding: 16px 0px 0px 0px;
      .solution-time{
  margin-bottom: 8px;

font-size: 14px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: bold;
color: #080404;
line-height: 20px;
}
.solution-title{
  margin-bottom: 16px;

font-size: 22px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: bold;
color: #080404;
line-height: 30px;
}
.solution-des{
margin-bottom: 32px;

font-size: 13px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #080404;
line-height: 19px;
}
    }
    flex-direction: column-reverse;
  }
  .solution:nth-of-type(2n+1){
  flex-direction: column-reverse;
}
}
.form-wrap{
  margin: 52px auto;
  display: flex;
  max-height: 463px;
  .form-content{
    background: #212327;
    box-sizing: border-box;
    padding: 30px 32px;
    color: #fff;
    flex: 1;
    .form-tit{
      margin-bottom: 24px;
font-size: 28px;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: bold;
color: #F4F4F4;
line-height: 40px;
    }
    .form-tip{
      font-weight: normal;
      opacity: 0.8;
      margin-bottom: 24px;
    }
    .form-cate{
      margin-bottom: 16px;
font-size: 16px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 22px;
cursor: pointer;
display: flex;
align-items: center;
img{
  margin-right: 6px;
  vertical-align: middle;
  width: 16px;
  height: 16px;
}
    }
    .section-more{
       background:#F8BA05;
       margin-top: 32px;
       justify-content: center;
       img{
         margin-left: 12px;
       }
    }
  }
  > img{
    flex: 0 0 50%;
    width: 50%;
    object-fit: cover;
  }
}
@media (max-width: 768px) {
.form-wrap{
  margin: 24px auto;
  display: flex;
  > img{
    display: none;
  }
  .form-content{
    background: #212327;
    box-sizing: border-box;
    padding: 24px;
    color: #fff;
    flex: 1;
    .form-tit{
      margin-bottom: 12px;
font-size: 22px;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: bold;
color: #F4F4F4;
line-height: 30px;
    }
    .form-tip{
      font-weight: normal;
      opacity: 0.8;
      margin-bottom: 12px;
    }
    .form-cate{
      margin-bottom: 16px;
font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 22px;
cursor: pointer;
    }
  }
}
}
@media (max-width: 768px) {
  .main {
    .main-bannar {
      display: none;
    }
    .main-bannar-phone {
      width: 100%;
      display: inline-block;
      object-fit: cover;
      // height: 100%;
      position: absolute;
      // left: 0;
      // right: 0;
      top: 0;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
    .main-content {
      .main-section {
        width: auto;
        padding-left: 0px;
        text-align: inherit;
        height: 270px;
        float: none;
        position: static;
        top: 50%;
        transform: none;
        right: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      position: relative;
      top: 0%;
      left: 0%;
      transform: none;
      margin-top: -374px;
      height: auto;
      padding: 64px 16px 48px;
      .main-title {
        font-size: 24px;
        float: none;
        // color: rgba(0, 35, 60, 1);
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .main-des {
        text-align: center;
        margin-top: 0px;
        // color: rgba(0, 35, 60, 0.5);
        line-height: 18px;
        font-size: 14px;
        max-width: 100%;
        padding: 0px 34px;
        // margin-bottom: 60px;
      }
      .main-btn {
        display: none;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 64px;
      }
    }
  }
}
</style>