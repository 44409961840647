<template>
  <div class="header-wrap">
    <div class="header">
      <!-- <div class="header-top">
        <div class="w1200">
          <Input
            search
            v-model="searchValue"
            size="small"
            style="width:193px"
            @on-search="goSearch"
          />
          <i>|</i>
          <span @click="changeLang" class="cur-pointer">{{ $t("message.changeLang") }}</span>
          <i>|</i>
          <Dropdown placement="bottom-end" v-if="userInfo.UserName">
            <a href="javascript:void(0)" style="color:rgb(0, 35, 60);">{{userInfo.UserName}}</a>
            <Dropdown-menu slot="list">
              <Dropdown-item v-for="(item,index) in userInfo.Systems" :key="index">
                <a style="color:rgb(0, 35, 60)" :href="item.Url">{{item.Name}}</a>
              </Dropdown-item>
              <Dropdown-item>
                <a @click="loginOut">退出</a>
              </Dropdown-item>
            </Dropdown-menu>
          </Dropdown>
          <router-link tag="span" to="/login" class="cur-pointer" v-else>
            <img style="margin-right:8px" src="../assets/icon_admin.png" alt />
            {{ $t("message.login") }}
          </router-link>
        </div>
      </div> -->
      <div class="header-bottom">
        <div class="w1200">
          <router-link tag="span" to="/" class="cur-pointer">
            <img class="logo" src="../assets/phone-logo.png" alt srcset />
          </router-link>
          <ul>
            <li
              v-for="(item,index) in navs"
              :key="index"
              class="cur-pointer"
              @click="goPage(item)"
            >{{item.name}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="header-phone">
      <div class="header-phone-top">
        <router-link to="/" style="margin-right:auto">
          <img class="logo" src="../assets/phone-logo.png" />
        </router-link>
        <!-- <img @click="toggleSearch" src="../assets/icon_search.png" />
        <span @click="changeLang">{{ $t("message.Language") }}</span>
        <Dropdown style="margin-left: 20px" placement="bottom-end" v-if="userInfo.UserName">
          <a href="javascript:void(0)" style="color:rgb(0, 35, 60);">
            {{userInfo.UserName}}
            <Icon type="arrow-down-b"></Icon>
          </a>
          <Dropdown-menu slot="list">
            <Dropdown-item v-for="(item,index) in userInfo.Systems" :key="index">
              <a style="color:rgb(0, 35, 60)" :href="item.Url">{{item.Name}}</a>
            </Dropdown-item>
            <Dropdown-item>
              <a @click="loginOut">退出</a>
            </Dropdown-item>
          </Dropdown-menu>
        </Dropdown>
        <img v-else @click="goLogin" src="../assets/icon_admin.png" /> -->
        <img @click="toggleNavs" v-if="!showNavs" src="../assets/icon_menu.png" />
        <img @click="toggleNavs" v-else src="../assets/icon_menu_close.png" />
      </div>
      <div class="header-phone-detail" v-if="showSearch || showNavs">
        <!-- <div class="header-phone-search cur-pointer" v-if="showSearch">
          <Input search v-model="searchValue">
            <Button
              @click="goSearch"
              type="info"
              slot="append"
              style="color:#fff;background-color: #2db7f5;border-color: #2db7f5;"
              icon="ios-search"
            >搜索</Button>
          </Input>
        </div> -->
        <div class="header-phone-navs" v-if="showNavs">
          <div
            @click="goPage(item)"
            class="header-phone-nav cur-pointer"
            v-for="(item,key,index) in navs"
            :key="index"
          >
            <!-- <span>0{{index+1}}</span> -->
            {{item.name}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      showSearch: false,
      showNavs: false,
      searchValue: "",
      isLogin: false,
      navs: [],
      userInfo: {},
    };
  },
  mounted() {
    this.getMenu();
    if (localStorage.getItem("userInfo")) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    }
    this.$EventBus.$on("doLogin", (msg) => {
      // A发送来的消息
      console.log("userInfo", msg);
      localStorage.setItem("userInfo", JSON.stringify(msg));
      this.userInfo = msg;
    });
  },
  methods: {
    changeLang() {
      if (this.$i18n.locale == "cn") {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "cn";
      }
    },
    toggleSearch() {
      this.showNavs = false;
      this.showSearch = !this.showSearch;
    },
    toggleNavs() {
      this.showNavs = !this.showNavs;
      this.showSearch = false;
    },
    //搜索
    goSearch() {
      this.showSearch = false;
      this.showNavs = false;
      this.$router.push("/search?keywords=" + this.searchValue);
    },
    goLogin() {
      this.$router.push("/login");
    },
    loginOut() {
      if (localStorage.getItem("userInfo")) {
        localStorage.removeItem("userInfo");
      }
      this.userInfo = "";
      
      this.$router.push("/login");
      return;
      let self = this;
      this.$axios.post(this.CusWebRoot + 'Cus/User/Login/SignOutApi').then(function (res) {
        console.log(res);
        self.$router.push("/login");
      });
    },
    //主导航
    getMenu() {
      let self = this;
      this.$axios
        .get(`/api/portal/navMenu/menu?lang=${this.$i18n.locale}&nav=main`)
        .then(function (res) {
          self.navs = res.data.data;
        });
    },
    goPage(item) {
      this.showSearch = false;
      this.showNavs = false;
      if (item.href.indexOf("http") > -1) {
        window.location.href = item.href;
        return;
      }
      let id = item.href.substr(-2);
      console.log(item, id);
      id = id.replace("=", "");
      let name = item.name;
      if (item.name == "首页" || item.name == "Home") {
        this.$router.push("/");
        return;
      }
      // if(isNaN(id)){
      //   return
      // }
      console.log(id, item.name);
      let self = this;

      if (item.lcj_type == "category") {
       let cates = document.getElementById('cates');
       cates.scrollIntoView(true);
       document.body.scrollTop=document.documentElement.scrollTop = cates.offsetTop - 90
        // this.$axios
        // .get(`/api/portal/categories/${id}&lang=${this.$i18n.locale}`)
        // .then(function(res) {
        //   console.log(res);
        // if(res.data.data.type == 'file'){
        // self.$router.push(`/tabledownload?id=${id}&name=${name}`);
        // }else{
        //     self.$router.push(`/news?id=${id}&name=${name}`);
        // }
        // });
        this.$EventBus.$emit('goNav',id)
      } else if(item.lcj_type == "extra_page"){
        this.$router.push("/");
        let solution = document.getElementById('solution')
       solution.scrollIntoView(true)
       
document.body.scrollTop=document.documentElement.scrollTop = solution.offsetTop - 90

      } else {
        this.$router.push("/detail?id=" + id);
      }
    },
  },
  watch: {
    "$i18n.locale"() {
      this.getMenu();
      this.$router.push("/");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header-wrap {
  height: 80px;
}
.header {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 999;
  box-shadow: -1px 6px 5px -2px rgba(0, 0, 0, 0.2);
}
.header-top {
  box-sizing: border-box;
  height: 48px;
  line-height: 48px;
  background: rgba(255, 255, 255, 1);
  color: #080404;
}
.header-top i {
  line-height: 48px;
  margin: 0px 16px;
}
.header-top span {
  line-height: 48px;
}
.header-top span img {
  vertical-align: sub;
}
.header-top .w1200 {
  font-size: 12px;
  text-align: right;
}
.header-bottom {
  height: 80px;
  box-sizing: border-box;
  line-height: 80px;
  background: rgba(255, 255, 255, 1);
  border-top: 1px solid #dbdbdb;
}
.header-bottom .logo {
  width: 220px;
  // height: 45px;
  line-height: 80px;
  vertical-align: middle;
  max-width: 320px;
}
.header-bottom ul {
  float: right;
  overflow: hidden;
  // padding-top: 20px;
}
.header-bottom ul li {
  float: left;
  margin-left: 3vw;
  font-size: 15px;
  font-weight: 400;
  color: #080404;
  line-height: 25px;
  line-height: 80px;
}
.header-phone {
  display: none;

  .header-phone-top {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #fff;
    box-shadow: 0px 4px 16px 0px rgba(0, 35, 60, 0.08);
    padding: 0px 24px;
    z-index: 9999;

    img,
    span {
      margin-left: 16px;
    }
    img.logo {
      margin-right: auto;
      margin-left: 0px;
      height: 26px;
       vertical-align: middle;
    }
  }
  .header-phone-detail {
    position: fixed;
    top: 60px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(255, 255, 255, 1);
    z-index: 9999;
    .header-phone-search {
      background: #fff;
      padding: 16px;
    }
    .header-phone-navs {
      padding: 24px 0px 0px 24px;
      .header-phone-nav {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        color: #212529;
        line-height: 56px;
        span {
          display: inline-block;
          font-size: 20px;
          transform: rotate(-90deg);
          font-weight: 300;
          color: rgba(0, 35, 60, 0.5);
          line-height: 31px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .header-wrap {
    height: auto;
  }
  .header {
    display: none;
  }
  .header-phone {
    display: block;
  }
}
</style>
