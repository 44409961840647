import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import locale from 'view-design/dist/locale/en-US';
import VueI18n from 'vue-i18n';
import en from 'view-design/dist/locale/en-US';
import cn from 'view-design/dist/locale/zh-CN';
import api from './api/config'
import * as custom from './api/utils';
import EasySlider from 'vue-easy-slider'

Vue.use(EasySlider)
Vue.prototype.$axios = api
Vue.prototype.$EventBus = new Vue()
const imgUrl = 'https://wwwadmin.minstech.cn/upload/'
Vue.prototype.imgUrl = imgUrl


Object.keys(custom).forEach((key) => {/*引入全局过滤器*/
  Vue.filter(key, custom[key])
})

Vue.use(ViewUI, { locale });
Vue.config.productionTip = false
Vue.use(VueI18n);
Vue.locale = () => {};


const messages = {
    en: Object.assign( require('./common/lang/en.js'), en),
    cn: Object.assign( require('./common/lang/zh.js'), cn)
};

const i18n = new VueI18n({
    locale: 'cn',  // set locale
    messages  // set locale messages
});
new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
