import axios from "axios"
axios.defaults.baseURL = process.env.NODE_ENV == "development" ? '/' : "https://wwwadmin.minstech.cn/"
axios.defaults.headers.common["Content-Type"] = "application/json; charset=UTF-8"
// 请求拦截器 添加token
axios.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器即异常处理
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.resolve(error)
  }
)


export default {
  baseUrl:axios.defaults.baseURL,
  // get请求
  get(url, param) {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url,
        params: param,
        headers:{
        },
      })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // post请求
  post(url, param) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url,
        data: param,
      })
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // all get
  allGet(fnArr) {
    return axios.all(fnArr)
  },
  // jsonp
  jsonp(url) {
    console.log(axios.defaults.loginURL,url)
    if(!url){
        console.error('请传入一个url参数')
        return;
    }
    return new Promise((resolve,reject) => {
        window.jsonCallBack =(result) => {
            resolve(result)
        }
        var JSONP=document.createElement("script");
        JSONP.type="text/javascript";
        JSONP.src=`${url.indexOf('http') > -1 ? url : axios.defaults.loginURL + url }&callback=jsonCallBack`;
        document.getElementsByTagName("head")[0].appendChild(JSONP);
        setTimeout(() => {
            document.getElementsByTagName("head")[0].removeChild(JSONP)
        },500)
    })
  } 
}